<template>
  <v-card class="elevation-0">
    <v-toolbar
      dense
      card
    >
      <v-toolbar-title><small>Mobile numbers</small></v-toolbar-title>
      <v-spacer />
      <v-speed-dial
        v-if="!mnpResubmit"
        v-model="fab"
        top
        fixed
        open-on-hover
        right
        direction="left"
        transition="slide-x-reverse-transition"
      >
        <v-btn
          slot="activator"
          v-model="fab"
          color="pink"
          dark
          fab
          hover
          small
        >
          <v-icon>add</v-icon>
          <v-icon>close</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <v-btn
            slot="activator"
            :color="mnpClass"
            fab
            dark
            small
            @click.native.stop="mnpDialog = !mnpDialog"
          >
            <v-icon>{{ mnpIcon }}</v-icon>
          </v-btn>
          <span>MNP</span>
        </v-tooltip>
        <v-tooltip bottom>
          <v-btn
            slot="activator"
            :disabled="disabled"
            :color="newClass"
            fab
            dark
            small
            @click.native.stop="newDialog = !newDialog"
          >
            <v-icon>{{ newIcon }}</v-icon>
          </v-btn>
          <span>New number</span>
        </v-tooltip>
        <v-tooltip bottom>
          <v-btn
            slot="activator"
            :disabled="disabled"
            :color="spClass"
            fab
            dark
            small
            @click.native.stop="showAddSp"
          >
            <v-icon>{{ spIcon }}</v-icon>
          </v-btn>
          <span>SP</span>
        </v-tooltip>
        <!--v-tooltip bottom>
          <v-btn
            fab
            dark
            :disabled="disabled"
            slot="activator"
            small
            @click.native.stop="showAddSp"
            :color="searchClass"
          >
            <v-icon>{{ searchIcon }}</v-icon>
          </v-btn>
          <span>Special request</span>
        </v-tooltip-->
      </v-speed-dial>
    </v-toolbar>
    <v-list
      dense
      two-line
      subheader
    >
      <v-subheader inset>
        SP, New & MNP list
      </v-subheader>
      <!--v-alert
        :color="listAlert"
        :icon="listAlert"
        :value="!items.length"
      >
        <p>Please add at least one number to register.</p>
        <p><strong>TIPS -&nbsp;</strong>You can register a combination of SP, New and MNP numbers under one registration profile all at once.</p>
      </v-alert-->
      <v-list-tile
        v-for="(thisItem, i) in items"
        :key="thisItem.msisdn"
        avatar
        @click="showEditRegistrationItem(i)"
      >
        <v-list-tile-avatar>
          <v-icon :color="item.iconClass">
            {{ thisItem.icon }}
          </v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ thisItem.detail }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ thisItem.iccid }}</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action v-if="!mnpResubmit">
          <v-btn
            :ripple="false"
            icon
            @click.stop="deleteMsisdn(i)"
          >
            <v-icon color="grey">
              mdi-delete-circle
            </v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
    <v-dialog
      v-model="spDialog"
      :max-width="maxWidth"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
    >
      <sp-dialog
        v-model="items"
        :dialog.sync="spDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="newDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      max-width="550px"
    >
      <new-msisdn-dialog
        v-model="items"
        :dialog.sync="newDialog"
        :selections="selections"
      />
    </v-dialog>
    <v-dialog
      v-model="mnpDialog"
      :max-width="maxWidth"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
    >
      <mnp-dialog
        v-model="items"
        :dialog.sync="mnpDialog"
        :initial-donor="initialDonor"
      />
    </v-dialog>
    <v-dialog
      v-model="updateDialog"
      :max-width="maxWidth"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
    >
      <edit-msisdn-dialog
        v-model="registrationItem"
        :dialog.sync="updateDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import formField from '@/mixins/form-field'
import { maxRegistrations } from '@/config'
import {
  searchClass, searchIcon,
  spClass, spIcon,
  mnpClass, mnpIcon,
  newClass, newIcon,
} from '@/utils'

export default {
  components: {
    EditMsisdnDialog: () => import(/* webpackChunkName: "editMsisdnDialog" */ '@/components/EditMsisdnDialog'),
    MnpDialog: () => import(/* webpackChunkName: "mnpDialog" */ '@/components/MnpDialog'),
    NewMsisdnDialog: () => import(/* webpackChunkName: "newMsisdnDialog" */ '@/components/NewMsisdnDialog'),
    SpDialog: () => import(/* webpackChunkName: "spDialog" */ '@/components/SpDialog'),
  },
  mixins: [formField],
  data () {
    return {
      editIndex: -1,
      fab: false,
      initialDonor: null,
      item: {
        detail: null,
        donor: null,
        exclusive: false,
        iccid: null,
        icon: null,
        iconClass: null,
        msisdn: null,
        plan: {},
        // price: null,
        // sellingPrice: null,
        value: false,
      },
      listAlert: 'info',
      maxWidth: '390px',
      mnpClass: mnpClass,
      mnpDialog: false,
      mnpIcon: mnpIcon,
      newClass: newClass,
      newDialog: false,
      newIcon: newIcon,
      selections: [],
      searchClass: searchClass,
      searchIcon: searchIcon,
      spClass: spClass,
      spDialog: false,
      spIcon: spIcon,
      updateDialog: false,
    }
  },
  computed: {
    disabled: function () {
      let count = 0
      this.items.forEach(item => {
        if (item.icon === this.spIcon || item.icon === this.newIcon) count++
      })
      return count >= maxRegistrations
    },
    items: function () {
      return this.field.props.items
    },
    mnpResubmit: function () {
      return this.field.props.mnpResubmit
    },
    registrationItem: function () {
      return this.editIndex > -1 ? this.items[this.editIndex] : this.item
    },
  },
  watch: {
    mnpDialog: function (val) {
      if (!val) {
        this.initialDonor = null
        return
      }

      let donors = []
      this.items.forEach(item => item.donor && donors.push(item.donor))
      donors = [...new Set(donors)]
      if (donors.length === 1) this.initialDonor = donors[0]
    },
  },
  mounted: function () {
    this.$set(this.field, 'validate', val => {
      if (this.items.length) return true
      else {
        this.listAlert = 'error'
        return false
      }
    })
  },
  methods: {
    deleteMsisdn: function (index) {
      this.items[index].icon === newIcon && this.selections.push(this.items[index])
      this.items.splice(index, 1)
      this.editIndex = -1
    },
    showAddSp: function () {
      this.spDialog = true
    },
    showEditRegistrationItem: function (index) {
      this.editIndex = index
      this.updateDialog = true
    },
  },
}
</script>

<!--style lang="stylus" scoped>
.speed-dial--top.speed-dial--absolute {
    top: 75%;
    transform: translateY(-50%);
}
</style-->
